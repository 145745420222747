import { createStore } from 'vuex'
import http from '../axios' 
import createPersistedState from 'vuex-persistedstate'  
export default createStore({
  state: {
   
    sourceUrl: '',
    requirementList: [],
    productionList: [],
    plantList: [],
    processList: [],
    userList: [],
    showDP: false,
    httpUrl: 'https://afh-f.xyz/api/v1'// 正式
    // httpUrl: 'http://39.99.176.179:20310/api/v1'// 测试
  
  },
  getters: {
  },
  mutations: {
    setRequirementList (state, obj) {
      state.requirementList = obj
    },
    setSourceUrl (state, url) {
      state.sourceUrl = url
    },
    SET_PRODUCT (state, data) {  
      state.productionList = data  
    },
    SET_PLANT (state, data) {  
      state.plantList = data  
    },
    SET_PROCESS (state, data) {
      state.processList = data  
    }, 
    SET_USERLIST (state, data) {
      state.userList = data  
    },
    setDPstatus (state, status) {
      state.showDP = status
    }
  },
  actions: {
    // 获取产品的列表选项
    async getProduct ({ commit }) {  
      const data = {
        keywords: ''
      }
      try {  
        const response = await http.post(`${this.state.httpUrl}/product/list`, data)  
        const list = []
        response.data.forEach((item) => {
          list.push({
            label: item.product_name,
            value: item.product_name,
            status: item.status
          })
        })
        commit('SET_PRODUCT', list)  
      } catch (error) {
        console.error('Error fetching items:', error)
      }  
    },
    // 获取工厂的列表选项
    async getPlantList ({ commit }) {  
      try {  
        const response = await http.get(`${this.state.httpUrl}/factory/list`)  
        const list = []
        response.data.forEach((item) => {
          list.push({
            label: item.name,
            value: item.factory_id
          })
        })
        console.log(list, 'list>>>>>>>>>>444')
        commit('SET_PLANT', list)  
      } catch (error) {
        console.error('Error fetching items:', error)
      }  
    },
    // 获取对应工厂的工序的列表选项
    async getProcessList ({ commit }, params) {  
      const data = {
        factory_id: params.factory_id
      }
      try {  
        const response = await http.post(`${this.state.httpUrl}/factory/process/list`, data)  
        const list = []
        response.data.forEach((item) => {
          list.push({
            label: item.process,
            value: item.process_id
          })
        })
        commit('SET_PROCESS', list)  
      } catch (error) {
        console.error('Error fetching items:', error)
      }  
    },
    // 获取系统用户列表
    async getUserList ({ commit }) {
      try {
        const response = await http.get('/account/list')
        const list = []
        response.data.forEach(item => {
          list.push({
            label: item.username,
            value: item.username
          })
        })
        commit('SET_USERLIST', list)  
      } catch (error) {
        console.error('Error fetching items:', error)
      }
    }
  },
  modules: {
  },
  plugins: [  
    createPersistedState({  
      key: 'my-app', // 存储数据的键名，默认为 'vuex'  
      storage: window.sessionStorage, // 存储位置，默认为 localStorage，也可以设置为 sessionStorage  
      paths: ['productionList'] // 指定需要持久化的状态路径数组，默认为 undefined，表示持久化整个 state  
    })  
  ]  
})
